import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":"","disabled":!_vm.iconName},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":""}},'v-btn',Object.assign({}, attrs, _vm.$attrs, {icon: !!_vm.iconName, text: !_vm.iconName}),false),Object.assign({}, _vm.$listeners, on)),[(_vm.iconName)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.iconName)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$attrs.label)+_vm._s(_vm.itemsCountLabel))])],1)]}}])},[(_vm.iconName)?_c('span',[_vm._v(_vm._s(_vm.$attrs.label))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }