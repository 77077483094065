<!-- price adjuster page -->
<template>
  <scrollable>
    <template #sticky>
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ $t('Bulk Price Updater') }}</span>
        </v-card-title>
        <v-divider />
      </v-card>
    </template>
    <template #scrollable-content="{ heightExceeded }">
      <v-container class="price_manager_wrapper">
        <div class="px-4 price_manager_wrapper">
          <div class="price_manager">
            <v-col col="12">
              <v-row>
                <v-col class="col-6 px-0 py-0">
                  <h3>{{ $t('Bulk Price Updater') }}</h3>
                </v-col>
                <v-col class="col-6 text-right px-0 py-0">
                  <v-btn outlined elevation="0" color="#ffffff" class="btn ml-3 add_price_btn"
                    @click="redirectToDetailPageDefualt">
                    {{ $t('+ &nbsp;Add Product pricing') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </div>
        <div class="table_container filter_section">
          <v-col col="12">
            <v-row>
              <v-col class="col-6">
                <v-row>
                  <v-col class="col-8 d-flex flex-wrap align-center">
                    <span class="heading_title"><strong>Bulk Price Updater Search</strong></span>
                    <v-text-field v-model="inputValue" label="Type Keyword" outlined></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col-6">
                <v-row>
                  <v-col class="col-8 d-flex flex-nowrap align-center ml-auto justify-end">
                    <span class="heading_title"><strong>Show price</strong></span>
                    <v-select v-model="selectedItem_order" :items="dropdownItems_order" label="Search By Order" outlined
                      class="price_class"></v-select>
                    <v-btn outlined elevation="0" color="#8C8F94" class="btn ml-3 view_btn">
                      {{ $t('View') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-12 px-0 py-0">
                <v-data-table :headers="headers" :items="returnData" :sort-desc.sync="sortDesc" :sort-by.sync="sortBy"
                  :item-class="customRowClass">
                  <template v-slot:item="props">
                    <tr>
                      <td class="custom-column" v-html="props.item.showEditButton"
                        @click="redirectToDetailPage(props.item.id)"></td>
                      <td class="custom-column">{{ props.item.title || 'N/A' }}</td>
                      <td class="custom-column">
                        {{ props.item.price ? props.item.price + '%' : '' }} {{ props.item.priceStatus }}
                      </td>
                      <td class="custom-column"> {{ props.item.brands.map(brand => brand.name).join(', ') || 'N/A' }}
                      </td>
                      <td class="custom-column"> {{ props.item.categories.map(category => category.name).join(', ') ||
                        'N/A' }}</td>
                      <td class="custom-column">
                        <!-- Check if ProductsSKU is an array and has elements -->
                        {{ Array.isArray(props.item.ProductsSKU) && props.item.ProductsSKU.length > 0
                          ? props.item.ProductsSKU.length + ' products'
                          : 'N/A'
                        }}
                        <!-- Tooltip only if ProductsSKU has elements -->
                        <div v-if="Array.isArray(props.item.ProductsSKU) && props.item.ProductsSKU.length > 0"
                          class="product_tooltip">
                          <span class="tooltip_btn">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.91223 0.320312C3.22056 0.320312 0.230469 3.3104 0.230469 7.00207C0.230469 10.6937 3.22056 13.6838 6.91223 13.6838C10.6039 13.6838 13.594 10.6937 13.594 7.00207C13.594 3.3104 10.6039 0.320312 6.91223 0.320312ZM7.5804 10.343H6.24405V6.3339H7.5804V10.343ZM7.5804 4.99754H6.24405V3.66119H7.5804V4.99754Z"
                                fill="#333333" />
                            </svg>
                          </span>
                          <div class="info_tooltip">
                            <div class="arrow-up"></div>
                            <p>
                              {{ props.item.ProductsSKU.slice(0, 5).join(', ') }}{{ props.item.ProductsSKU.length > 5 ?
                              ' ...' : '' }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="custom-column">
                        {{
                          props.item.effective == 'always'
                            ? props.item.effective
                            : props.item.startDate + ' to ' + props.item.endDate
                        }}
                      </td>
                      <td class="custom-column">
                        <div>Jacob Stone</div>
                        <div class="price_date">
                          Last Modified:
                          <br />
                          {{ props.item.authorModifies }}
                        </div>
                      </td>
                      <td class="custom-column">
                        <v-icon @click="deletePrice(props.item.id)">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <v-dialog v-model="confirmDialog" width="600" height="600" persistent>
            <v-card>
              <!-- Custom dialog title using the slot -->
              <template>
                <div class="delete-dialog-bulk">
                  <span class="delete-message">
                    {{ $t('Are you sure') }} {{ $t('you want to delete this bulk') }}?
                  </span>
                </div>
              </template>

              <!-- Dialog Actions -->
              <v-card-actions>
                <v-btn color="red" text @click="confirmDelete">Yes</v-btn>
                <v-btn color="grey" text @click="cancelDelete">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-container>
    </template>
    <template #sticky-bottom="{ heightExceeded }">
      <v-divider />
      <v-footer v-if="heightExceeded" class="d-flex align-center pa-4 flex-gap-16 justify-end"></v-footer>
    </template>
  </scrollable>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Scrollable from '@/components/layouts/Scrollable.vue'
import ButtonAction from '@/components/common/actions/ButtonAction.vue'
import { useApi } from '@/composable/useApi'
import { useToasts } from '@/composable/useToasts';


export default defineComponent({
  name: 'Price',
  components: { Scrollable, ButtonAction },
  data() {
    return {
      sortBy: 'Month',
      sortDesc: false,
      inputValue: '',
      imagePath: 'imagePath',
      selectedMonth: [],
      selectedYear: [],
      returnData: [],
      confirmDialog: false,
      headers: [
        { text: '', value: 'showEditButton', class: 'custom-header', sortable: false },
        { text: 'Title', value: 'title', class: 'custom-header' },
        { text: 'Price', value: 'price', class: 'custom-header', sortable: false },
        { text: 'Brands', value: 'brands', class: 'custom-header' },
        { text: 'Categories', value: 'categories', class: 'custom-header' },
        { text: 'Products SKU', value: 'ProductsSKU', class: 'custom-header product_sku' },
        { text: 'Effective', value: 'effective', class: 'custom-header' },
        { text: 'Author', value: 'author', class: 'custom-header', sortable: false },
        { text: '', value: '', class: 'custom-header', sortable: false }
      ],
      dropdownItems_order: [
        { text: '10% Decrease', value: '10% Decrease' },
        { text: '15% Decrease', value: '15% Decrease' }
      ],
      selectedItem_order: null,
      editedIndex: -1,
      editedItem: {
        title: '',
        price: '',
        brands: '',
        categories: '',
        effective: '',
        author: '',
        authorModifies: ''
      },
      showInfo: {
        '1a': false
      }
    }
  },
  computed: {},
  mounted() {
    this.fetchDataFromAPI()
  },
  methods: {
    async fetchDataFromAPI() {
      const api = useApi();
      api.client
        .get(`/epic/bulk-price-list`, {
          params: { sort: '-created_at', per_page: -1 }, // Modify pagination if needed
        })
        .then((response) => {
          this.data = response.data.data || []; // Assuming 'items' is the key in response containing the data
          this.filteredData(); // Apply filtering after fetching data
        }).catch(error => {
          console.error('Error fetching data from API:', error);
        });
    },
    filteredData() {
      this.data.forEach((item) => {
        item.showEditButton = `
          <button class="edit_btn edit_icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
              <path d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z"/>
            </svg>
          </button>`;
      });

      if (this.selectedMonth.length === 0 && this.selectedYear.length === 0) {
        this.returnData = this.data;
      } else {
        this.returnData = this.data.filter((item) => {
          return (
            (this.selectedMonth.length === 0 || this.selectedMonth.includes(item.Month.split(' ')[0])) &&
            (this.selectedYear.length === 0 || this.selectedYear.includes(item.Month.split(' ')[1]))
          );
        });
      }
    },
    customRowClass(item) {
      return {
        'custom-row': true,
        'custom-row-even': this.data.indexOf(item) % 2 === 0,
        'custom-row-odd': this.data.indexOf(item) % 2 !== 0
      }
    },
    getLastModified(author) {
      const lastIndex = author.lastIndexOf('Last Modified:')
      if (lastIndex !== -1) {
        return author.substring(lastIndex)
      }
      return ''
    },

    redirectToDetailPage(id) {
      this.$router.push('/panel/1/1/catalog/PriceDetail/' + id)
    },
    redirectToDetailPageDefualt() {
      this.$router.push('/panel/1/1/catalog/PriceDetail')
    },
    deletePrice(id) {
      this.itemToDelete = id;
      this.confirmDialog = true; // Open the dialog
    },
    // Handle the confirmation of the delete action
    confirmDelete() {
      const { addSuccessToast } = useToasts();
      const api = useApi(); // Assuming `useApi` is your API instance
      api.client
        .delete(`/epic/bulk-price-delete/${this.itemToDelete}`)
        .then((response) => {
          addSuccessToast('delete bulk successfully!');
          this.data = response.data.data || [];
          this.fetchDataFromAPI(); // Fetch updated data after deletion
          this.confirmDialog = false; // Close the dialog after deletion
        })
        .catch((error) => {
          console.error("Error deleting item from API:", error);
          this.confirmDialog = false; // Close the dialog even if there's an error
        });
    },
    // Handle the cancellation of the delete action
    cancelDelete() {
      this.confirmDialog = false; // Close the dialog without deletion
    },
  }
})
</script>

<style>
.delete-dialog-bulk {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.delete-message {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
</style>
